import { Button, Form, InputNumber, Modal, message } from 'antd';
import { setToken, submitCode } from '../../../api';

import PropTypes from 'prop-types';
import React from 'react';

const SecuerPin = (props) => {
  const { isModalVisible, handleCancel, getAllCodesApi, data } = props;

  const handleSubmit = async (values) => {
    await setToken();
    const res = await submitCode({
      putData: { ...values, code: data.code },
      method: 'PUT',
    });
    if (res.success) {
      //export that codes
      getAllCodesApi();
      handleCancel();
    } else {
      message.error(res.message);
    }
  };
  return (
    <Modal
      title={data.securePin ? 'Update Secure Pin' : 'Set Secure Pin'}
      centered
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="basic"
        onFinish={handleSubmit}
        onFinishFailed={(errorInfo) => {
          console.log('Failed:', errorInfo);
        }}
      >
        <Form.Item
          label="Secure Pin"
          name="securePin"
          rules={[
            { required: true, message: 'Please input your secure pin!' },
          ]}
          initialValue={data.securePin ? data.securePin : ''}
        >
          <InputNumber min={1} maxLength={4} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

SecuerPin.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  getAllCodesApi: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default SecuerPin;
