import { Button, Card, Input, Layout, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

import { login } from '../../api';
import { useHistory } from 'react-router-dom';

const { Content } = Layout;

const Signin = (props) => {
  const [ userName, setUserName ] = useState('');
  const [ userPassword, setUserPassword ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);
  let history = useHistory();

  useEffect(() => {
    const pwa_storage = JSON.parse(localStorage.getItem('pwa_storage'));
    if (pwa_storage) {
      const { isLogin } = pwa_storage;
      if (isLogin) {
        if (history.location.state && history.location.state.mediaId) {
          history.push(`/${history.location.state.mediaId}`, {});
        } else {
          history.push('/admin/codes');
        }
      }
    }
  });

  const handleSignin = async () => {
    setIsLoading(true);
    const postData = {
      username: userName,
      password: userPassword,
    };
    const res = await login(postData);
    if (res.success) {
      console.log({ res });
      const user = { ...res.data.user };
      delete user.contactNo;
      delete user.createdAt;
      delete user.updatedAt;
      localStorage.setItem(
        'pwa_storage',
        JSON.stringify({ isLogin: true, token: res.data.accessToken, user })
      );
      if (history.location.state && history.location.state.mediaId) {
        history.push(`/${history.location.state.mediaId}`, {});
      } else {
        history.push('/admin/codes');
      }
    } else {
      message.error(res.message);
    }
    setIsLoading(false);
  };

  return (
    <Layout className="main-layout">
      <Content style={{ padding: '0 1rem' }}>
        <div className="card-container">
          <Card className="custom-card" bordered={false}>
            <h1>SignIn</h1>
            <div>
              <Input
                placeholder="Username"
                prefix={<UserOutlined />}
                style={{ marginBottom: '15px' }}
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
              <Input
                type="password"
                placeholder="Password"
                prefix={<LockOutlined />}
                style={{ marginBottom: '15px' }}
                value={userPassword}
                onChange={(e) => {
                  setUserPassword(e.target.value);
                }}
              />
              <div style={{ textAlign: 'right' }}>
                <Button
                  type="primary"
                  loading={isLoading}
                  style={{ marginRight: '10px' }}
                  onClick={handleSignin}
                >
                  {isLoading ? 'Loading' : 'Submit'}
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </Content>
    </Layout>
  );
};

export default Signin;
