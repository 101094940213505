import { Card, Layout, Spin, message } from 'antd';
import React, { useEffect, useState, Suspense } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Audiocard from '../../components/Audio/Audiocard';
import Footer from '../../components/Footer';
import MethodOptions from '../../components/Media/MethodOptions';
import PinVerify from '../../components/Media/PinVerify';
import Recordaudio from '../../components/Audio/Customrecorder';
import Urlcard from '../../components/Url/Urlcard';
import Urlupload from '../Urlupload';
import VideoRecorder from '../../components/Video/VideoRecorder';
import Videocard from '../../components/Video/Videocard';
import { getCodeById } from '../../api';
import { increasePlayCount } from '../../utility/helper';
// import DownTimer from '../Timer/DownTimer';
// import UpTimer from '../Timer/UpTimer';

const DownTimer = React.lazy(() => import('../Timer/DownTimer'));
const UpTimer = React.lazy(() => import('../Timer/UpTimer'));

const { Content } = Layout;

const Media = (props) => {
  const { mediaId } = useParams();
  let history = useHistory();

  const [ activeMediaType, setActiveMediaType ] = useState('');
  const [ isCodeExits, setIsCodeExits ] = useState(false);
  const [ isFileExits, setIsFileExits ] = useState(false);
  // const [ isAudioLink, setIsAudioLink ] = useState(true);
  const [ mediaType, setMediaType ] = useState('Audio');
  const [ fileLink, setFileLink ] = useState('');
  const [ isLoading, setIsLoading ] = useState(true);

  const [ codeDetails, setCodeDetails ] = useState(null);

  const [ isPinVerified, setIsPinVerified ] = useState(false);

  useEffect(() => {
    getMediaLink(mediaId);
  }, []);

  const handlePinVerification = (values) => {
    if (values.securePin === codeDetails.securePin) {
      setIsPinVerified(true);
    } else {
      message.error("Pin didn't match");
    }
  };

  const backLink = () => setActiveMediaType('');

  // this will get the code details from the server if exits
  const getMediaLink = async (mediaId) => {
    try {
      const res = await getCodeById(mediaId);
      console.log('🚀 ~ file: index.jsx ~ line 48 ~ getMediaLink ~ res', res);
      console.log({ history });

      if (res.success) {
        if (
          res.data.user_id !== null &&
          res.data.user_id !== undefined &&
          res.data.isUsed === false
        ) {
          const pwa_storage = JSON.parse(localStorage.getItem('pwa_storage'));
          if (pwa_storage) {
            if (!pwa_storage.isLogin) {
              history.push('/admin', { mediaId });
            }
          } else {
            history.push('/admin', { mediaId });
          }
        }
        setIsCodeExits(true);
        setCodeDetails(res.data);
        if (res.data.isUsed) {
          await increasePlayCount(res.data);
          setIsFileExits(true);
          setFileLink(res.data.filePath);
          setMediaType(res.data.fileType ? res.data.fileType : 'Audio');
        }
        if (
          res.data.codeType === 'DownTimer' ||
          res.data.codeType === 'UpTimer'
        ) {
          setIsFileExits(true);
          setMediaType(res.data.codeType);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  // this will set which Ui should be rendered
  const getMediaUI = () => {
    if (isLoading) {
      return (
        <Card className="custom-card" bordered={false}>
          <Spin tip="Loading..." />
        </Card>
      );
    } else if (isCodeExits) {
      if (isFileExits) {
        if (codeDetails && codeDetails.securePin && !isPinVerified) {
          return <PinVerify handleSubmit={handlePinVerification} />;
        } else {
          if (mediaType === 'Audio') {
            return <Audiocard fileLink={fileLink} />;
          } else if (mediaType === 'Video') {
            return <Videocard fileLink={fileLink} />;
          } else if (mediaType === 'Url') {
            return <Urlcard fileLink={fileLink} />;
          } else if (mediaType === 'DownTimer') {
            return (
              <Suspense fallback={<div>Loading...</div>}>
                <DownTimer codeDetails={codeDetails} />
              </Suspense>
            );
          } else if (mediaType === 'UpTimer') {
            return (
              <Suspense fallback={<div>Loading...</div>}>
                <UpTimer codeDetails={codeDetails} />
              </Suspense>
            );
          } else {
            return <p>Unvalid type</p>;
          }
        }
      } else if (activeMediaType === 'Record Audio') {
        return (
          <Recordaudio
            backLink={backLink}
            mediaId={mediaId}
            usedFor="record"
            codeDetails={codeDetails}
          />
        );
      } else if (activeMediaType === 'Record Video') {
        return <VideoRecorder backLink={backLink} codeDetails={codeDetails} />;
      } else if (activeMediaType === 'Upload Audio') {
        return (
          <Recordaudio
            backLink={backLink}
            mediaId={mediaId}
            usedFor="upload"
            codeDetails={codeDetails}
          />
        );
      } else if (activeMediaType === 'Upload Link') {
        return (
          <Urlupload
            backLink={backLink}
            mediaId={mediaId}
            usedFor="upload"
            codeDetails={codeDetails}
          />
        );
      } else {
        return <MethodOptions setActiveMediaType={setActiveMediaType} />;
      }
    } else {
      return (
        <Card className="custom-card" bordered={false}>
          <h1>
            You don't authorised to use this Code. Conact admin for further
            details.
          </h1>
        </Card>
      );
    }
  };

  return (
    <Layout className="main-layout">
      <Content style={{ padding: '0 1rem' }}>
        <div className="card-container">{getMediaUI()}</div>
        <Footer />
      </Content>
    </Layout>
  );
};

export default Media;
