// import * as App from '../../helper/constants';

import {
  Button,
  Card,
  Layout,
  Popconfirm,
  Select,
  Table,
  Tag,
  Tooltip,
  message,
} from 'antd';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  getRequestsByPagination,
  getUsersByType,
  setToken,
  submitRequest,
} from '../../api';

import Header from '../../components/Admin/Common/Header';
import SubmitRequest from '../../components/Admin/Codes/SubmitRequest';
import moment from 'moment';

const { Content } = Layout;

const Requests = (props) => {
  let history = useHistory();
  const pwa_storage = JSON.parse(localStorage.getItem('pwa_storage'));

  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState([]);

  const [ isModalVisible, setIsModalVisible ] = useState(false);
  const [ userList, setUserList ] = useState([]);
  const [ tableSettings, setTableSettings ] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
    filter: {},
    sortBy: [ [ 'createdAt', 'desc' ] ],
  });

  const columns = [
    {
      title: 'Request Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (createdAt) => {
        return moment(createdAt).format('DD/MM/YYYY HH:mm');
      },
    },
    {
      title: 'Code Count',
      dataIndex: 'codeCount',
      key: 'codeCount',
    },
    {
      title: 'Approved Status',
      dataIndex: 'isApproved',
      key: 'isApproved',
      render: (isApproved) =>
        isApproved ? (
          <Tag color="success">Yes</Tag>
        ) : (
          <Tag color="error">No</Tag>
        ),
    },
    {
      title: 'Approved Date',
      dataIndex: 'approvedAt',
      key: 'approvedAt',
      sorter: true,
      render: (approvedAt) => {
        return approvedAt ? moment(approvedAt).format('DD/MM/YYYY HH:mm') : '-';
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: '100px',
      render: (id, record) => {
        if (
          pwa_storage &&
          pwa_storage.user &&
          pwa_storage.user.type === 'Admin'
        ) {
          return (
            <div>
              {!record.isApproved && (
                <Tooltip title="Approve Request">
                  <Button
                    type="primary"
                    shape="circle"
                    onClick={() => approveRequest(id)}
                    icon={<CheckCircleOutlined />}
                  />
                </Tooltip>
              )}
              <Popconfirm
                placement="bottomRight"
                title="Are you sure you want to delete this request?"
                onConfirm={() => {
                  alert('yes');
                }}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Cancle Request">
                  <Button
                    type="danger"
                    shape="circle"
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </div>
          );
        } else {
          return record.isApproved ? (
            '-'
          ) : (
            <Popconfirm
              placement="bottomRight"
              title="Are you sure you want to delete this request?"
              onConfirm={() => {
                alert('yes');
              }}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Cancle Request">
                <Button
                  type="danger"
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          );
        }
      },
    },
  ];

  if (pwa_storage && pwa_storage.user && pwa_storage.user.type === 'Admin') {
    columns.splice(0, 0, {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (user) => `${user.firstName} ${user.lastName}`,
    });
  }

  const getRequestsByPaginationApi = async (manualPostData = null) => {
    setIsLoading(true);
    const postData = {
      currentPage:
        manualPostData && manualPostData.currentPage
          ? manualPostData.currentPage
          : tableSettings.currentPage,
      pageSize:
        manualPostData && manualPostData.pageSize
          ? manualPostData.pageSize
          : tableSettings.pageSize,
      sortBy:
        manualPostData && manualPostData.sortBy
          ? manualPostData.sortBy
          : tableSettings.sortBy,
      filter:
        manualPostData && manualPostData.filter
          ? manualPostData.filter
          : tableSettings.filter,
      searchText: '',
    };
    const res = await getRequestsByPagination(postData);
    console.log(res);
    if (res.success) {
      setData(res.data.data);
      console.log({
        currentPage: res.data.currentPage,
        pageSize: res.data.pageSize,
        total: res.data.total,
        totalPages: res.data.totalPages,
      });
      setTableSettings({
        currentPage: res.data.currentPage,
        pageSize: res.data.pageSize,
        total: res.data.total,
        totalPages: res.data.totalPages,
      });
    } else {
      if (res.redirect) {
        history.push('/admin');
      } else {
        message.error(res.message);
      }
    }
    setIsLoading(false);
  };

  const getUserList = async () => {
    setIsLoading(true);
    const postData = {
      type: 'Normal',
    };
    const res = await getUsersByType(postData);
    console.log({ res });
    if (res.success) {
      setUserList(
        res.data.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }))
      );
    } else {
      message.error(res.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (pwa_storage) {
      const { isLogin } = pwa_storage;
      if (!isLogin) {
        history.push('/admin');
      }
      setToken();
      getRequestsByPaginationApi();
      getUserList();
    } else {
      history.push('/admin');
    }
  }, []);

  const approveRequest = async (id) => {
    setIsLoading(true);
    const putData = {
      id,
    };
    await submitRequest({ putData, method: 'PUT' });
    setIsLoading(false);
    await getRequestsByPaginationApi();
  };

  return (
    <Layout className="main-layout">
      <Content style={{ padding: '0 1rem' }}>
        <div className="card-container">
          <Card className="bootstrap-container" bordered={false}>
            <Header />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h1>Requests</h1>
              <Link to="/admin/codes">Go to Code Page</Link>
            </div>

            <div
              style={{
                marginBottom: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Tooltip title="Reload">
                  <Button
                    type="text"
                    shape="circle"
                    icon={<ReloadOutlined />}
                    onClick={getRequestsByPaginationApi}
                  />
                </Tooltip>
                {pwa_storage &&
                pwa_storage.user &&
                pwa_storage.user.type === 'Admin' && (
                  <Select
                    onChange={(value) => {
                      let data = { ...tableSettings };
                      data.currentPage = 1;
                      if (value) {
                        data.filter = { userId: value };
                      } else {
                        data.filter = {};
                      }
                      setTableSettings({ ...data });
                      getRequestsByPaginationApi({ ...data });
                    }}
                    placeholder="Select User"
                    options={userList}
                    style={{ minWidth: '200px' }}
                    allowClear={true}
                  />
                )}
                {pwa_storage &&
                pwa_storage.user &&
                pwa_storage.user.type === 'Normal' && (
                  <Button onClick={() => setIsModalVisible(true)}>
                    Submit Request
                  </Button>
                )}
              </div>
              <div />
            </div>
            <Table
              columns={columns}
              dataSource={data}
              loading={isLoading}
              size="middle"
              selections={true}
              bordered={true}
              scroll={{ y: 240, x: 1200 }}
              pagination={{
                showQuickJumper: true,
                showSizeChanger: true,
                // defaultCurrent={2},
                total: tableSettings.total,
                showTotal: (total) => `Total ${total} items`,
                onChange: (page, pageSize) => {
                  const newTableSettings = {
                    ...tableSettings,
                    currentPage: page,
                    pageSize,
                  };
                  setTableSettings({ ...newTableSettings });
                  getRequestsByPaginationApi(newTableSettings);
                },
              }}
              onChange={(pagination, filters, sorter) => {
                const sortArray = [];
                console.log({ pagination });
                if (sorter) {
                  if (sorter.column) {
                    sortArray.push([
                      sorter.columnKey,
                      sorter.order === 'ascend' ? 'asc' : 'desc',
                    ]);
                  }
                  const newTableSettings = {
                    ...tableSettings,
                    sortBy: sortArray,
                    currentPage: 1,
                  };
                  setTableSettings({ ...newTableSettings });
                  getRequestsByPaginationApi(newTableSettings);
                }
              }}
              showSorterTooltip={true}
            />
          </Card>
        </div>
        <SubmitRequest
          isModalVisible={isModalVisible}
          handleCancel={() => setIsModalVisible(false)}
          userList={userList}
        />
      </Content>
    </Layout>
  );
};

export default Requests;
