import React, { useEffect, useRef } from 'react';

import { Card } from 'antd';
import PropTypes from 'prop-types';
import Share from '../Share';

const Videocard = (props) => {
  const { fileLink } = props;

  const videoElement = useRef(null);

  useEffect(() => {
    videoElement.current.play();
  }, []);

  return (
    <div>
      <div className="welcome-section">
        <h1>Welcome to QuickCloudCodes</h1>
      </div>
      <Card className="custom-card" bordered={false} style={{ marginBottom: '15px' }}>
        <h2 style={{ marginBottom: '15px' }}>Play video</h2>
        <div>
          <video width="320" height="240" controls autoPlay={true} ref={videoElement}>
            <source src={fileLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Card>
      <Card className="custom-card" bordered={false}>
        <Share />
      </Card>
    </div>
  );
};

Videocard.propTypes = {
  fileLink: PropTypes.string.isRequired
};

export default Videocard;
