import { Button, Card, Form, InputNumber } from 'antd';

import PropTypes from 'prop-types';
import React from 'react';

const PinVerify = (props) => {
  const { handleSubmit } = props;
  return (
    <div>
      <div className="welcome-section">
        <h1>Welcome to QuickCloudCodes</h1>
      </div>
      <Card className="custom-card" bordered={false} style={{ marginBottom: '15px' }}>
        <h2 style={{ marginBottom: '15px' }}>Submit pin to get the media.</h2>
        <Form
          name="basic"
          onFinish={handleSubmit}
          onFinishFailed={(errorInfo) => {
            console.log('Failed:', errorInfo);
          }}
        >
          <Form.Item
            label="Enter Pin"
            name="securePin"
            rules={[ { required: true, message: 'Please input your secure pin!' } ]}
          >
            <InputNumber min={1} maxLength={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

PinVerify.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default PinVerify;
