import { Button, Input, message } from 'antd';
import {
  CopyOutlined,
  DownloadOutlined,
  FacebookOutlined,
  MailOutlined,
  QrcodeOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { Link, useParams } from 'react-router-dom';
import React, { useState } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import Modal from 'antd/lib/modal/Modal';
// import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

const Share = (props) => {
  const shareLink = window.location.href;
  const { mediaId } = useParams();

  const [ isOpenModal, setIsOpenModal ] = useState(false);
  const pwa_storage = JSON.parse(localStorage.getItem('pwa_storage'));

  const downloadQrCode = () => {
    let link = document.createElement('a');
    link.download = `${mediaId}.png`;
    link.href = document.getElementById('mycanvas').toDataURL();
    link.click();
  };
  return (
    <React.Fragment>
      <div className="social-links-section">
        <div className="social-buttons-section">
          <FacebookShareButton
            className="ant-btn ant-btn-primary ant-btn-icon-only facebook-btn"
            url={shareLink}
            size={32}
            resetButtonStyle={false}
          >
            <FacebookOutlined />
          </FacebookShareButton>
          <TwitterShareButton
            className="ant-btn ant-btn-primary ant-btn-icon-only twitter-btn"
            url={shareLink}
            resetButtonStyle={false}
          >
            <TwitterOutlined />
          </TwitterShareButton>
          <WhatsappShareButton
            className="ant-btn ant-btn-primary ant-btn-icon-only whatsapp-btn"
            url={shareLink}
            resetButtonStyle={false}
          >
            <WhatsAppOutlined />
          </WhatsappShareButton>
          <EmailShareButton
            className="ant-btn ant-btn-primary ant-btn-icon-only email-btn"
            url={shareLink}
            resetButtonStyle={false}
          >
            <MailOutlined />
          </EmailShareButton>
        </div>
        <div>
          <Input
            defaultValue={shareLink}
            disabled={true}
            addonAfter={
              <CopyToClipboard
                text={shareLink}
                onCopy={() => {
                  message.success('Link Copied!!');
                }}
              >
                <CopyOutlined />
              </CopyToClipboard>
            }
          />
        </div>
      </div>
      <div className="action-buttons">
        <Button
          type="primary"
          icon={<QrcodeOutlined />}
          onClick={() => setIsOpenModal(true)}
        >
          QR Code
        </Button>
        <Button type="primary">Order Code</Button>
        {pwa_storage &&
        pwa_storage.isLogin && (
          <Link to="/admin">
            <Button type="primary">My codes</Button>
          </Link>
        )}
      </div>
      <Modal
        // title="Basic Modal"
        visible={isOpenModal}
        onCancel={() => {
          setIsOpenModal(false);
        }}
        footer={null}
        centered
        closable
        style={{ textAlign: 'center' }}
      >
        <div style={{ marginBottom: '15px' }}>
          <QRCode
            value={shareLink}
            id="mycanvas"
            level="H"
            style={{ width: '100%', height: 'auto', maxWidth: '180px' }}
          />
        </div>
        <div>
          <Button
            onClick={downloadQrCode}
            type="primary"
            icon={<DownloadOutlined />}
          >
            Download QR Code
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

// Share.propTypes = {
//   fileLink: PropTypes.string.isRequired
// };

export default Share;
