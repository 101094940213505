// import * as App from '../../helper/constants';

import { Button, Card, Input, Layout, message } from 'antd';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

import { signUp } from '../../api';
import { useHistory } from 'react-router-dom';

const { Content } = Layout;

const SignUp = (props) => {
  const history = useHistory();

  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  // const [ verificationCode, setVerificationCode ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);
  // const [ displaySignup, setDisplaySignup ] = useState(true);

  useEffect(() => {
    const pwa_storage = JSON.parse(localStorage.getItem('pwa_storage'));
    if (pwa_storage) {
      const { isLogin } = pwa_storage;
      if (isLogin) {
        history.push('/admin/codes');
      }
    }
  });

  const handleUserSubmit = async () => {
    setIsLoading(true);
    const postData = {
      firstName,
      lastName,
      email,
      password,
    };
    const res = await signUp(postData);
    if (res.success) {
      history.push('/admin/signin');
      message.success(res.message);
    } else {
      message.error(res.message);
    }
    setIsLoading(false);
  };

  return (
    <Layout className="main-layout">
      <Content style={{ padding: '0 1rem' }}>
        <div className="card-container">
          <Card className="custom-card" bordered={false}>
            <h1>SignUp</h1>
            <div>
              <Input
                placeholder="Firstname"
                prefix={<UserOutlined />}
                style={{ marginBottom: '15px' }}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <Input
                placeholder="Lastname"
                prefix={<UserOutlined />}
                style={{ marginBottom: '15px' }}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <Input
                type="email"
                placeholder="Email"
                prefix={<MailOutlined />}
                style={{ marginBottom: '15px' }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                type="password"
                placeholder="Password"
                prefix={<LockOutlined />}
                style={{ marginBottom: '15px' }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div style={{ textAlign: 'right' }}>
                <Button
                  type="primary"
                  loading={isLoading}
                  style={{ marginRight: '10px' }}
                  onClick={handleUserSubmit}
                >
                  {isLoading ? 'Loading' : 'Submit'}
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </Content>
    </Layout>
  );
};

export default SignUp;
