import {
  Button,
  Card,
  Layout,
  Popconfirm,
  Table,
  Tooltip,
  message,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  deleteSubscription,
  getSubscriptionsByPagination,
  setToken,
} from '../../api';

import { DeleteOutlined } from '@ant-design/icons';
import Header from '../../components/Admin/Common/Header';
import moment from 'moment';

const { Content } = Layout;

const Subscription = (props) => {
  let history = useHistory();
  const pwa_storage = JSON.parse(localStorage.getItem('pwa_storage'));

  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState([]);
  const [ tableSettings, setTableSettings ] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
    filter: {},
    sortBy: [ [ 'createdAt', 'desc' ] ],
  });

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (createdAt) => {
        return moment(createdAt).format('DD/MM/YYYY HH:mm');
      },
    },
    {
      title: 'Updated Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      render: (updatedAt) => {
        return moment(updatedAt).format('DD/MM/YYYY HH:mm');
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (val) => {
        return (
          <Tooltip title="Delete">
            <Popconfirm
              placement="bottomRight"
              title="Are you sure you want to delete"
              onConfirm={() => deleteSubscriptionApi(val)}
              okText="Yes"
              cancelText="No"
            >
              <Button shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        );
      },
    },
  ];

  const deleteSubscriptionApi = async (id) => {
    console.log({ id });
    setToken();
    const res = await deleteSubscription({ subscriptionIds: [ id ] });
    if (res.success) {
      message.success(res.message);
      getSubscriptions();
    } else {
      message.success(res.message);
    }
  };

  const getSubscriptions = async (manualPostData = null) => {
    setIsLoading(true);
    const postData = {
      currentPage:
        manualPostData && manualPostData.currentPage
          ? manualPostData.currentPage
          : tableSettings.currentPage,
      pageSize:
        manualPostData && manualPostData.pageSize
          ? manualPostData.pageSize
          : tableSettings.pageSize,
      sortBy:
        manualPostData && manualPostData.sortBy
          ? manualPostData.sortBy
          : tableSettings.sortBy,
      filter: [],
      searchText: '',
    };
    const res = await getSubscriptionsByPagination(postData);
    console.log(res);
    if (res.success) {
      setData(res.data.data);
      setTableSettings({
        currentPage: res.data.currentPage,
        pageSize: res.data.pageSize,
        total: res.data.total,
        totalPages: res.data.totalPages,
      });
    } else {
      if (res.redirect) {
        history.push('/admin');
      } else {
        message.error(res.message);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (pwa_storage) {
      const { isLogin } = pwa_storage;
      if (!isLogin) {
        history.push('/admin');
      }
      const defaultApiCall = async () => {
        await setToken();
        await getSubscriptions();
      };
      defaultApiCall();
    } else {
      history.push('/admin');
    }
  }, []);

  return (
    <Layout className="main-layout">
      <Content style={{ padding: '0 1rem' }}>
        <div className="card-container">
          <Card className="bootstrap-container" bordered={false}>
            <Header />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h1>Subscriptions</h1>
              <Link to="/admin/codes">Go to Code Generate Page</Link>
            </div>
            <Table
              columns={columns}
              dataSource={data}
              loading={isLoading}
              size="middle"
              bordered={true}
              scroll={{ y: 240 }}
              pagination={{
                showQuickJumper: true,
                showSizeChanger: true,
                total: tableSettings.total,
                showTotal: (total) => `Total ${total} items`,
                onChange: (page, pageSize) => {
                  const newTableSettings = {
                    ...tableSettings,
                    currentPage: page,
                    pageSize,
                  };
                  setTableSettings({ ...newTableSettings });
                  getSubscriptions(newTableSettings);
                },
              }}
              onChange={(pagination, filters, sorter) => {
                const sortArray = [];
                console.log({ pagination });
                if (sorter) {
                  if (sorter.column) {
                    sortArray.push([
                      sorter.columnKey,
                      sorter.order === 'ascend' ? 'asc' : 'desc',
                    ]);
                  }
                  const newTableSettings = {
                    ...tableSettings,
                    sortBy: sortArray,
                    currentPage: 1,
                  };
                  setTableSettings({ ...newTableSettings });
                  getSubscriptions(newTableSettings);
                }
              }}
              showSorterTooltip={true}
            />
          </Card>
        </div>
      </Content>
    </Layout>
  );
};

export default Subscription;
