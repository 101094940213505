import 'antd/dist/antd.css';
import './App.css';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Codes from './containers/Admin/Codes';
import Dashboard from './components/Dashboard';
import Media from './containers/Media';
import React from 'react';
import Requests from './containers/Admin/Requests';
import Signin from './containers/Admin/Signin';
import Signup from './containers/Admin/Signup';
import Subscription from './containers/Admin/Subscription';
import Video from './containers/Video';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/admin/signup" component={Signup} />
        <Route path="/admin/codes" component={Codes} />
        <Route path="/admin/subscriptions" component={Subscription} />
        <Route path="/admin/requests" component={Requests} />
        <Route path="/admin" component={Signin} />
        <Route path="/video-demo" component={Video} />
        <Route path="/:mediaId" component={Media} />
        <Route path="/" component={Dashboard} />
      </Switch>
    </Router>
  );
}

export default App;
