import { Button, Card, Layout } from 'antd';
import React from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import VideoRecorder from '../../components/Video/VideoRecorder';

const { Content } = Layout;

const Video = (props) => {
  const { backLink } = props;
  return (
    <Layout className="main-layout">
      <Content style={{ padding: '0 1rem' }}>
        <div className="card-container">
          <Card className="custom-card" bordered={false}>
            <Button
              type="link"
              icon={<ArrowLeftOutlined />}
              style={{ position: 'absolute', left: 0 }}
              onClick={backLink}
            >
              Back
            </Button>
            <h1>Record Video</h1>
            <VideoRecorder />
          </Card>
        </div>
      </Content>
    </Layout>
  );
};

export default Video;
