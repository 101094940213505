import React from 'react';
import { useHistory } from 'react-router-dom';

const Header = (props) => {
  let history = useHistory();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid',
        marginBottom: '20px',
      }}
    >
      <div>
        <h1>QuickCloudCodes</h1>
      </div>
      <div
        style={{
          color: '#1890ff',
          textDecoration: 'none',
          backgroundColor: 'transparent',
          outline: 'none',
          cursor: 'pointer',
          transition: 'color 0.3s',
        }}
        onClick={() => {
          localStorage.clear();
          history.push('/admin');
        }}
      >
        logout
      </div>
      {/* <a
        href="javascript:void(0)"
        onClick={() => {
          localStorage.clear();
          history.push('/admin');
        }}
      >
        logout
      </a> */}
    </div>
  );
};

export default Header;
