import { Button, Card, Form, Input, Layout, Modal } from 'antd';
import React, { useState } from 'react';

import DynamoDB from '../helper/dynamodb';
import Footer from './Footer';

// import PropTypes from 'prop-types';

const { Content } = Layout;

const Dashboard = (props) => {
  const [ isModalVisible, setIsModalVisible ] = useState(false);
  const [ form ] = Form.useForm();

  const onFinish = async (values) => {
    const { email } = values;
    const dynamodb = new DynamoDB();
    const putData = {
      Item: {
        email: {
          S: email
        }
      },
      ReturnValues: 'ALL_OLD',
      ReturnConsumedCapacity: 'INDEXES',
      TableName: 'pwa-subscription'
    };
    await dynamodb.putItemInTable(putData);
    handleCancel();
    Modal.success({
      title: 'Thank you!',
      content: 'We will be in touch with details about ordering your own QuickCloudCodes!',
      centered: true
    });
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  return (
    <Layout className="main-layout">
      <Content style={{ padding: '0 1rem' }}>
        <div className="card-container">
          <div>
            <div className="welcome-section">
              <h1>Welcome to QuickCloudCodes</h1>
            </div>
            <Card className="custom-card" bordered={false}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* <h1>QuickCloudCodes</h1> */}
                <Button type="primary" style={{ marginBottom: '15px' }} onClick={showModal}>
                  Order Stikers
                </Button>
                <Button type="primary" style={{ marginBottom: '15px' }}>
                  Make your own
                </Button>
              </div>
            </Card>
          </div>
          <Footer />
        </div>
        <Modal title="Subscription" footer={null} visible={isModalVisible} onCancel={handleCancel} centered={true}>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { type: 'email', message: 'Enter valid email' },
                { required: true, message: 'This feild is required' }
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
            <div style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Modal>
      </Content>
    </Layout>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
