import { Button, Card } from 'antd';
import {
  CloudUploadOutlined,
  LinkOutlined,
  PlayCircleOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';

import React from 'react';

const MethodOptions = (props) => {
  const { setActiveMediaType } = props;
  return (
    <div style={{ textAlign: 'center' }}>
      <div className="welcome-section">
        <h1>Welcome to QuickCloudCodes</h1>
      </div>
      <Card className="custom-card" bordered={false} style={{ margin: 'auto' }}>
        <h2 style={{ marginBottom: '15px' }}>Select Any One Of This Options</h2>
        <Button
          type="primary"
          shape="round"
          size="large"
          style={{ marginBottom: '15px' }}
          onClick={() => setActiveMediaType('Record Audio')}
          icon={<PlayCircleOutlined />}
        >
          Record Audio
        </Button>
        <br />
        <Button
          type="primary"
          shape="round"
          size="large"
          style={{ marginBottom: '15px' }}
          onClick={() => setActiveMediaType('Record Video')}
          icon={<VideoCameraOutlined />}
        >
          Record Video
        </Button>
        <br />
        <Button
          type="primary"
          shape="round"
          size="large"
          style={{ marginBottom: '15px' }}
          onClick={() => setActiveMediaType('Upload Audio')}
          icon={<CloudUploadOutlined />}
        >
          Upload Audio
        </Button>
        <br />
        <Button
          type="primary"
          shape="round"
          size="large"
          style={{ marginBottom: '15px' }}
          onClick={() => setActiveMediaType('Upload Link')}
          icon={<LinkOutlined />}
        >
          Upload Link
        </Button>
      </Card>
    </div>
  );
};

export default MethodOptions;
