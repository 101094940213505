import * as App from './constants';

import DynamoDB from 'aws-sdk/clients/dynamodb';

class dynamodb {
  constructor() {
    this.dynamodb = new DynamoDB({
      region: 'us-east-2',
      accessKeyId: App.AWS_ACCESS_KEY,
      secretAccessKey: App.AWS_SECRET_ACCESS_KEY,
      apiVersion: '2012-08-10'
    });
  }

  // dynamodb.listTables({}, function(err, data) {
  // 	if (err)
  // 		console.log(err, err.stack); // an error occurred
  // 	else console.log(data); // successful response
  // });

  // var params = {
  // 	ExpressionAttributeValues: {
  // 		':v1': {
  // 			N: '1'
  // 		}
  // 	},
  // 	KeyConditionExpression: 'id = :v1',
  // 	// ProjectionExpression: 'SongTitle',
  // 	TableName: 'filestore'
  // 	// ExpressionAttributeNames: {
  // 	// 	id: '1'
  // 	// }
  // };
  // dynamodb.query(params, function(err, data) {
  // 	if (err)
  // 		console.log(err, err.stack); // an error occurred
  // 	else console.log(data); // successful response
  // });

  /* putItemInTable : Used to upload add/update items in dynamodb*/
  /* @sampleInput : {  } */
  /* @author : kushal */
  async putItemInTable(params) {
    return this.dynamodb.putItem(params).promise();
  }

  async searchById(searchId) {
    const params = {
      ExpressionAttributeValues: {
        ':v1': {
          N: searchId
        }
      },
      KeyConditionExpression: 'id = :v1',
      TableName: 'filestore'
    };
    return this.dynamodb.query(params).promise();
  }

  async getPwaCodeById(searchId) {
    const params = {
      ExpressionAttributeValues: {
        ':v1': {
          S: searchId
        }
      },
      KeyConditionExpression: 'Id = :v1',
      TableName: App.DYNAMODB_TABLE
    };
    return this.dynamodb.query(params).promise();
  }

  async batchWriteItem(params) {
    return this.dynamodb.batchWriteItem(params).promise();
  }

  async scan(params) {
    return this.dynamodb.scan(params).promise();
  }
}

export default dynamodb;
