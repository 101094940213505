import * as App from './constants';

import SS3 from 'aws-sdk/clients/s3';

class s3 {
	constructor() {
		this.s3 = new SS3({
			region: 'us-east-2',
			accessKeyId: App.AWS_ACCESS_KEY,
			secretAccessKey: App.AWS_SECRET_ACCESS_KEY,
			apiVersion: '2006-03-01',
			params: { Bucket: App.APP_BUCKET }
		});
	}

	/* getObject : Used to get single file to s3*/
	/* @sampleInput : { Body: file, Key: fullKey, ContentType: file.type } */
	/* @author : kushal */
	async getObject(fileName) {
		const params = {
			Bucket: App.APP_BUCKET,
			Key: fileName
		};
		console.log({ params });
		// return this.s3.getObject(params).promise();
		this.s3.getObject(params, function(err, data) {
			if (err)
				console.log(err, err.stack); // an error occurred
			else console.log(data); // successful response
		});
	}

	/* singleFileUpload : Used to upload single file to s3*/
	/* @sampleInput : { Body: file, Key: fullKey, ContentType: file.type } */
	/* @author : kushal */
	async singleFileUpload(params) {
		return this.s3.upload(params).promise();
	}

	/* copyS3Object : Used to copy single object in same bucket*/
	/* @author : kushal */
	async copyS3Object(copyUrl, pasteUrl) {
		const params = {
			Bucket: App.APP_BUCKET,
			CopySource: copyUrl,
			Key: pasteUrl
		};
		return this.s3.copyObject(params).promise();
	}

	async deleteS3Object(s3Key) {
		try {
			const params = {
				Bucket: App.APP_BUCKET,
				Key: s3Key
			};
			return this.s3.deleteObject(params).promise();
		} catch (error) {
			console.log(error.message);
		}
	}
}

export default s3;
