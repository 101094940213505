import { Button, Form, InputNumber, Modal, message } from 'antd';
import { setToken, submitRequest } from '../../../api';

import PropTypes from 'prop-types';
import React from 'react';

const SubmitRequest = (props) => {
  const { isModalVisible, handleCancel } = props;

  const handleSubmit = async (values) => {
    await setToken();
    const res = await submitRequest({ putData: { ...values }, method: 'POST' });
    if (res.success) {
      handleCancel();
    } else {
      message.error(res.message);
    }
  };
  return (
    <Modal
      title="Submit Request"
      centered
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="basic"
        onFinish={handleSubmit}
        onFinishFailed={(errorInfo) => {
          console.log('Failed:', errorInfo);
        }}
      >
        <Form.Item
          label="Code Count"
          name="codeCount"
          rules={[
            { required: true, message: 'Please input your code count!' },
          ]}
        >
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

SubmitRequest.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  userList: PropTypes.object.isRequired,
};

export default SubmitRequest;
