import { Button, Card } from 'antd';
import React from 'react';

import PropTypes from 'prop-types';
import Share from '../Share';

const Urlcard = (props) => {
  const { fileLink } = props;

  return (
    <div>
      <div className="welcome-section">
        <h1>Welcome to QuickCloudCodes</h1>
      </div>
      <Card
        className="custom-card"
        bordered={false}
        style={{ marginBottom: '15px' }}
      >
        <h2 style={{ marginBottom: '15px' }}>
          Click below button to play media
        </h2>
        <div>
          <a href={fileLink} target="_blank">
            <Button>Click me</Button>
          </a>
        </div>
      </Card>
      <Card className="custom-card" bordered={false}>
        <Share />
      </Card>
    </div>
  );
};

Urlcard.propTypes = {
  fileLink: PropTypes.string.isRequired,
};

export default Urlcard;
