import { Button, Form, InputNumber, Modal, Select, message } from 'antd';
import { createCode, setToken } from '../../../api';

import PropTypes from 'prop-types';
import React from 'react';

const { Option } = Select;

const GenerateCode = (props) => {
  const { isModalVisible, handleCancel, userList, getAllCodesApi } = props;

  const handleSubmit = async (values) => {
    await setToken();
    const postParams = { ...values };
    const res = await createCode(postParams);
    if (res.success) {
      //export that codes
      getAllCodesApi();
      handleCancel();
    } else {
      message.error(res.message);
    }
  };
  return (
    <Modal title="Generate Codes" centered visible={isModalVisible} onCancel={handleCancel} footer={null}>
      <Form
        name="basic"
        onFinish={handleSubmit}
        onFinishFailed={(errorInfo) => {
          console.log('Failed:', errorInfo);
        }}
      >
        <Form.Item
          label="Code Count"
          name="codeCount"
          rules={[ { required: true, message: 'Please input your code count!' } ]}
        >
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item label="Assined User" name="user_id">
          <Select showSearch style={{ width: 200 }} placeholder="Select a person" allowClear={true}>
            <Option value="" key="user_11">
              Public Access
            </Option>
            {userList.map((user) => (
              <Option value={user.value} key={user.value}>
                {user.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Code Type"
          name="codeType"
          rules={[ { required: true, message: 'Please select one code type' } ]}
        >
          <Select style={{ width: 200 }} placeholder="Select Code Type" allowClear={false}>
            <Option value="Normal" key="Normal">
              Normal
            </Option>
            <Option value="UpTimer" key="UpTimer">
              UpTimer
            </Option>
            <Option value="DownTimer" key="DownTimer">
              DownTimer
            </Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

GenerateCode.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  userList: PropTypes.array.isRequired,
  getAllCodesApi: PropTypes.func.isRequired,
  type: PropTypes.string
};

export default GenerateCode;
