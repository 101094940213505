// import { DYNAMODB_TABLE } from '../helper/constants';
// import DynamoDB from '../helper/dynamodb';
// import moment from 'moment';
import { submitCode } from '../api';

export const jsonToCsv = async (json) => {
  const fields = Object.keys(json[0]);
  const replacer = function(key, value) {
    return value === null ? '' : value;
  };
  let csv = await json.map(function(row) {
    return fields
      .map(function(fieldName) {
        return JSON.stringify(row[fieldName], replacer);
      })
      .join(',');
  });
  csv.unshift(fields.join(',')); // add header column
  csv = csv.join('\r\n');
  return csv;
};

export const downloadCsv = (csvData, fileName = 'export.csv') => {
  const csvLink = document.createElement('a');
  csvLink.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
  csvLink.target = '_blank';
  csvLink.download = fileName;
  csvLink.click();
};

export const increasePlayCount = async (codeDetails) => {
  const putData = {
    code: codeDetails.code,
    playCount: parseInt(codeDetails.playCount) + 1,
  };
  await submitCode({ putData, method: 'PUT' });
};
