export const AWS_ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY;
export const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
export const APP_BUCKET = process.env.REACT_APP_BUCKET;
export const APP_AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const APP_AWS_USER_POOLID = process.env.REACT_APP_AWS_USER_POOLID;
export const APP_AWS_USER_POOL_WEB_CLIENTID = process.env.REACT_APP_AWS_USER_POOL_WEBCLIENTID;
export const BUCKET_URL = process.env.REACT_APP_AWS_BUCKET_URL;
export const BASEURL = process.env.REACT_APP_BASEURL;
export const DYNAMODB_TABLE = process.env.REACT_APP_DYNAMODB_TABLE;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
