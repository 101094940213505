import * as App from '../../helper/constants';

import { ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Modal, Progress, Spin, Upload, message } from 'antd';
import { ConvertM4aTomp3, submitCode } from '../../api';
import React, { useEffect, useRef, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';

import PropTypes from 'prop-types';
import S3 from 'aws-sdk/clients/s3';
// import moment from 'moment';

const Recordaudio = (props) => {
  const {
    mediaId,
    usedFor = 'record',
    backLink = () => {},
    codeDetails,
  } = props;

  const [ isLoading, setIsLoading ] = useState(false);
  const [ isConverting, setIsConverting ] = useState(false);

  const [ timer, setTimer ] = useState(0);
  const [ blobUrl, setBlobUrl ] = useState('');
  const [ blobData, setData ] = useState('');
  const [ isRecordingStarted, setIsRecordingStarted ] = useState(false);
  const [ uploadProgress, setUploadProgress ] = useState(0);

  const mediaStreamObj = useRef({});
  const mediaRecorder = useRef({});
  const dataArray = useRef([]);
  const time = useRef(0);
  const timeInterval = useRef(null);

  useEffect(() => {
    if (isMobile && isIOS) {
      info();
    }
  }, []);

  function info() {
    Modal.info({
      title: 'You are using iphone device',
      content: (
        <div>
          <p>Here are some info how you can upload audio.</p>
          <p>1. Open Voice memo application.</p>
          <p>2. Record audio in that app.</p>
          <p>3. Save that recorded audio.</p>
          <p>4. Upload that audio by click upload button.</p>
        </div>
      ),
      onOk() {},
    });
  }

  const startRecording = () => {
    navigator.getUserMedia(
      { audio: true },
      (mediaObject) => {
        mediaStreamObj.current = mediaObject;
        mediaRecorder.current = new MediaRecorder(mediaStreamObj.current);
        mediaRecorder.current.ondataavailable = function(ev) {
          dataArray.current.push(ev.data);
        };
        mediaRecorder.current.onstop = function(ev) {
          let audioData = new Blob(dataArray.current, { type: 'audio/mp3;' });
          dataArray.current = [];
          let audioSrc = window.URL.createObjectURL(audioData);
          setBlobUrl(audioSrc);
          setData(audioData);
        };
        mediaRecorder.current.start();
        setIsRecordingStarted(true);
        timeInterval.current = setInterval(() => {
          time.current++;
          setTimer(time.current);
        }, 1000);
      },
      () => {
        console.log('Permission Denied');
      }
    );
  };

  const stopRecording = () => {
    setIsRecordingStarted(false);
    mediaRecorder.current.stop();
    clearInterval(timeInterval.current);
    time.current = 0;
    setTimer(0);
  };

  const submitAudio = async (file = null) => {
    try {
      setIsLoading(true);
      const s3 = new S3({
        region: 'us-east-2',
        accessKeyId: App.AWS_ACCESS_KEY,
        secretAccessKey: App.AWS_SECRET_ACCESS_KEY,
        apiVersion: '2006-03-01',
        params: { Bucket: App.APP_BUCKET },
      });
      const d = new Date();
      const fileName = mediaId && mediaId !== '' ? mediaId : d.getTime();
      // const fullDateTime = moment(d).format('YYYY-MM-DD HH:mm:00');

      let s3Key = `${fileName}.mp3`;
      if (file !== null) {
        const extension = file.name.split('.').pop();
        s3Key = `${fileName}.${extension}`;
      }
      const submitedData = file !== null ? file : blobData;
      const file_config = {
        Body: submitedData,
        Key: s3Key,
        ContentType: file ? file.type : blobData.type,
      };

      s3
        .upload(file_config, async (err, data) => {
          if (err) {
            console.log('errrr', err);
            message(err.message);
            setIsLoading(false);
          } else {
            const keyExtension = s3Key.split('.').pop();
            let filePath = `${App.BUCKET_URL}${s3Key}`;
            if (keyExtension === 'm4a') {
              setIsConverting(true);
              const convertedResponse = await ConvertM4aTomp3(s3Key);
              filePath = convertedResponse.Response;
              setIsConverting(false);
            }

            const putData = {
              code: codeDetails.code,
              filePath: filePath,
              fileType: 'Audio',
              isUsed: true,
            };
            await submitCode({ putData, method: 'PUT' });
            resetRecord();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            setIsLoading(false);
          }
        })
        .on('httpUploadProgress', function(progress) {
          let uploaded = parseInt(progress.loaded * 100 / progress.total);
          setUploadProgress(uploaded);
        });
    } catch (error) {
      message.error(error.message);
      // console.log(error);
    }
  };

  const resetRecord = () => {
    setTimer(0);
    setBlobUrl('');
    setData('');
    setIsRecordingStarted(false);

    mediaStreamObj.current = {};
    mediaRecorder.current = {};
    dataArray.current = [];
    time.current = 0;
    timeInterval.current = null;
  };

  const uploadAttribute = {
    beforeUpload: (file) => {
      if (file.type !== 'audio/mpeg' && file.type !== 'audio/x-m4a') {
        message.error(`${file.name} is not a mp3 or m4a file`);
      }
      return file.type === 'audio/mpeg' || file.type === 'audio/x-m4a';
    },
    customRequest: ({ file }) => {
      submitAudio(file);
      console.log({ file });
    },
    accept: 'audio/mpeg,audio/x-m4a',
  };

  if (isLoading && isConverting) {
    return (
      <Card className="custom-card" bordered={false}>
        <Spin tip="converting..." />
      </Card>
    );
  } else if (isLoading && uploadProgress > 0) {
    return (
      <Card className="custom-card" bordered={false}>
        <Spin tip="Processing..." />
        <Progress percent={uploadProgress} status="active" />
      </Card>
    );
  } else if (isLoading) {
    return (
      <Card className="custom-card" bordered={false}>
        <Spin tip="Processing..." />
        <Progress percent={uploadProgress} status="active" />
      </Card>
    );
  } else {
    return (
      <div style={{ textAlign: 'center' }}>
        <div className="welcome-section">
          <h1>Welcome to QuickCloudCodes</h1>
        </div>
        <Card className="custom-card" bordered={false}>
          <Button
            type="link"
            icon={<ArrowLeftOutlined />}
            style={{ position: 'absolute', left: 0 }}
            onClick={backLink}
          >
            Back
          </Button>
          <h2 style={{ marginBottom: '15px' }}>
            {!isMobile && !isIOS && usedFor === 'record' ? (
              'Record'
            ) : (
              'Upload'
            )}{' '}
            Audio
          </h2>
          {blobUrl && blobUrl !== '' ? (
            <div>
              <audio controls>
                <source src={blobUrl} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
              <Button
                type="primary"
                size="large"
                onClick={() => submitAudio()}
                style={{ marginTop: '15px' }}
              >
                Submit
              </Button>
              <Button
                type="link"
                size="large"
                onClick={resetRecord}
                style={{ marginTop: '15px' }}
              >
                Reset
              </Button>
            </div>
          ) : (
            <div>
              {!isMobile &&
              !isIOS &&
              usedFor === 'record' && (
                <React.Fragment>
                  <div style={{ marginTop: '15px' }}>
                    {isRecordingStarted ? (
                      <Button
                        type="danger"
                        shape="round"
                        size="large"
                        onClick={stopRecording}
                        style={{
                          height: '5rem',
                          width: '5rem',
                          borderRadius: '50%',
                          padding: '0',
                        }}
                      >
                        <div style={{ fontSize: '2rem' }}>{timer}</div>
                        <div>Stop</div>
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        shape="round"
                        size="large"
                        onClick={startRecording}
                        style={{
                          height: '5rem',
                          width: '5rem',
                          borderRadius: '50%',
                          padding: '0',
                        }}
                      >
                        <div>Start</div>
                      </Button>
                    )}
                  </div>
                  {/* <div className="or-section-divider">
									<div className="or-text">
										<b className="text-ash">OR</b>
									</div>
									<div className="or-line" />
								</div> */}
                </React.Fragment>
              )}

              {usedFor === 'upload' && (
                <div>
                  <Upload {...uploadAttribute}>
                    <Button icon={<UploadOutlined />}>
                      Click to Upload mp3/m4v
                    </Button>
                  </Upload>
                </div>
              )}
            </div>
          )}
        </Card>
      </div>
    );
  }
};

Recordaudio.propTypes = {
  mediaId: PropTypes.string,
};

export default Recordaudio;
