import { CopyrightOutlined } from '@ant-design/icons';
import React from 'react';

const Footer = (props) => {
  return (
    <footer>
      <span className="footer-text">
        <CopyrightOutlined /> {new Date().getFullYear()} QuickCloudCodes. All
        rights reserved.
      </span>
    </footer>
  );
};

export default Footer;
