import React, { useEffect, useRef } from 'react';

import { Card } from 'antd';
import PropTypes from 'prop-types';
import Share from '../Share';

const Audiocard = (props) => {
  const { fileLink } = props;

  const audioElement = useRef(null);

  useEffect(() => {
    audioElement.current.play();
  }, []);

  return (
    <div>
      <div className="welcome-section">
        <h1>Welcome to QuickCloudCodes</h1>
      </div>
      <Card className="custom-card" bordered={false} style={{ marginBottom: '15px' }}>
        <h2 style={{ marginBottom: '15px' }}>Play audio</h2>
        <div>
          <audio controls autoPlay={true} ref={audioElement}>
            <source src={fileLink} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        </div>
      </Card>
      <Card className="custom-card" bordered={false}>
        <Share />
      </Card>
    </div>
  );
};

Audiocard.propTypes = {
  fileLink: PropTypes.string.isRequired
};

export default Audiocard;
