import * as App from '../../helper/constants';

import {
  Button,
  Card,
  Layout,
  Popconfirm,
  Select,
  Table,
  Tag,
  Tooltip,
  message,
} from 'antd';
import {
  ClearOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
  LockOutlined,
  PlayCircleOutlined,
  ReloadOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { downloadCsv, jsonToCsv } from '../../utility/helper';
import {
  getCodesByPagination,
  getUsersByType,
  setToken,
  submitCode,
} from '../../api';

import GenerateCode from '../../components/Admin/Codes/GenerateCode';
import Header from '../../components/Admin/Common/Header';
import S3 from '../../helper/s3';
import SecuerPin from '../../components/Admin/Codes/SecurePin';
import moment from 'moment';

const { Content } = Layout;
const BASEURL = App.BASEURL;

const Codes = () => {
  let history = useHistory();
  const pwa_storage = JSON.parse(localStorage.getItem('pwa_storage'));

  const [ isLoading, setIsLoading ] = useState(false);
  const [ codes, setCodes ] = useState([]);
  const [ selectedCodes, setSelectedCodes ] = useState([]);
  const [ selectedRowKeys, setSelectedRowKeys ] = useState([]);

  const [ isModalVisible, setIsModalVisible ] = useState(false);
  const [ userList, setUserList ] = useState([]);
  const [ tableSettings, setTableSettings ] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
    filter: {},
    sortBy: [ [ 'createdAt', 'desc' ] ],
  });

  const [ isPinModalVisible, setIsPinModalVisible ] = useState(false);
  const [ activePinCode, setActivePinCode ] = useState(null);
  const [ activeSecurePin, setActiveSecurePin ] = useState(null);

  const handlePinChangeClick = (securePin, code) => {
    setActivePinCode(code);
    setActiveSecurePin(securePin);
    setIsPinModalVisible(true);
  };

  const handlePinAddClick = (code) => {
    setActivePinCode(code);
    setActiveSecurePin(null);
    setIsPinModalVisible(true);
  };

  const handlePinModalClose = () => {
    setIsPinModalVisible(false);
    setActivePinCode(null);
    setActiveSecurePin(null);
  };

  const handleRemovePin = async (code) => {
    setIsLoading(true);
    await submitCode({ putData: { securePin: null, code }, method: 'PUT' });
    setIsLoading(false);
    await getAllCodesApi();
  };

  const columns = [
    {
      title: 'Link',
      dataIndex: 'code',
      key: 'code',
      render: (code, record, index) => {
        return (
          <a
            href={`${BASEURL}${code}`}
            target="_blank"
          >{`${BASEURL}${code}`}</a>
        );
      },
    },
    {
      title: 'Is Used',
      dataIndex: 'isUsed',
      key: 'isUsed',
      render: (isUsed) =>
        isUsed ? <Tag color="success">Yes</Tag> : <Tag color="error">No</Tag>,
    },
    {
      title: 'Code Type',
      dataIndex: 'codeType',
      key: 'codeType',
    },
    {
      title: 'File Type',
      dataIndex: 'fileType',
      key: 'fileType',
      render: (fileType) => {
        switch (fileType) {
          case 'Url':
            return (
              <Tag icon={<LinkOutlined />} color="#55acee">
                {fileType}
              </Tag>
            );
          case 'Audio':
            return (
              <Tag icon={<PlayCircleOutlined />} color="#55acee">
                {fileType}
              </Tag>
            );
          case 'Video':
            return (
              <Tag icon={<VideoCameraOutlined />} color="#55acee">
                {fileType}
              </Tag>
            );
          default:
            return '-';
        }
      },
    },
    {
      title: 'Play Count',
      dataIndex: 'playCount',
      key: 'playCount',
    },
    {
      title: 'Secure Pin',
      dataIndex: 'securePin',
      key: 'securePin',
      render: (securePin, record) => {
        return securePin ? (
          <div style={{ textAlign: 'center' }}>
            <div style={{ marginRight: '1rem' }}>{securePin}</div>
            <Tooltip title="Change Secure Pin">
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => handlePinChangeClick(securePin, record.code)}
              />
            </Tooltip>
            <Tooltip title="Remove Secure Pin">
              <Button
                type="danger"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => handleRemovePin(record.code)}
              />
            </Tooltip>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Tooltip title="Add Secure Pin">
              <Button
                type="primary"
                shape="circle"
                icon={<LockOutlined />}
                onClick={() => handlePinAddClick(record.code)}
              />
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => {
        return moment(createdAt).format('DD/MM/YYYY HH:mm');
      },
      defaultSortOrder: 'descend',
      sorter: true,
    },
    {
      title: 'Updated Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => {
        return moment(updatedAt).format('DD/MM/YYYY HH:mm');
      },
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: '100px',
      render: (id, record) => {
        if (
          pwa_storage &&
          pwa_storage.user &&
          pwa_storage.user.type === 'Admin'
        ) {
          return (
            <div>
              {record.isUsed && (
                <Popconfirm
                  placement="bottomRight"
                  title="Are you sure you want to remove file?"
                  onConfirm={() => {
                    removeFileLinkWithCode(
                      id,
                      record.filePath,
                      record.code,
                      'PUT'
                    );
                  }}
                  // onCancel={() => {
                  // 	console.log('Cancle');
                  // }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title="Clear File">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<ClearOutlined />}
                    />
                  </Tooltip>
                </Popconfirm>
              )}
              <Popconfirm
                placement="bottomRight"
                title="Are you sure you want to delete this code?"
                onConfirm={() =>
                  removeFileLinkWithCode(
                    id,
                    record.filePath,
                    record.code,
                    'DELETE'
                  )}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Delete Code">
                  <Button
                    type="danger"
                    shape="circle"
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </div>
          );
        } else {
          return record.isUsed ? (
            <Popconfirm
              placement="bottomRight"
              title="Are you sure you want to remove file?"
              onConfirm={() => {
                removeFileLinkWithCode(id, record.filePath, record.code, 'PUT');
              }}
              // onCancel={() => {
              // 	console.log('Cancle');
              // }}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Clear File">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<ClearOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          ) : (
            '-'
          );
        }
      },
    },
  ];

  if (pwa_storage && pwa_storage.user && pwa_storage.user.type === 'Admin') {
    columns.splice(2, 0, {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (user, record) =>
        user ? `${user.firstName} ${user.lastName}` : 'Guest',
    });
  }

  useEffect(() => {
    if (pwa_storage) {
      const { isLogin } = pwa_storage;
      if (!isLogin) {
        history.push('/admin');
      }
      setToken();
      getAllCodesApi();
      getUserList();
    } else {
      history.push('/admin');
    }
  }, []);

  const getAllCodesApi = async (manualPostData = null) => {
    setIsLoading(true);
    const postData = {
      currentPage:
        manualPostData && manualPostData.currentPage
          ? manualPostData.currentPage
          : tableSettings.currentPage,
      pageSize:
        manualPostData && manualPostData.pageSize
          ? manualPostData.pageSize
          : tableSettings.pageSize,
      sortBy:
        manualPostData && manualPostData.sortBy
          ? manualPostData.sortBy
          : tableSettings.sortBy,
      filter:
        manualPostData && manualPostData.filter
          ? manualPostData.filter
          : tableSettings.filter,
      searchText: '',
    };
    const res = await getCodesByPagination(postData);
    console.log(res);
    if (res.success) {
      // Add key for the row selection
      const newCodes = res.data.data.map((ele) => ({
        ...ele,
        key: ele.id,
      }));
      setCodes(newCodes);
      console.log({
        currentPage: res.data.currentPage,
        pageSize: res.data.pageSize,
        total: res.data.total,
        totalPages: res.data.totalPages,
      });
      setTableSettings({
        ...tableSettings,
        currentPage: res.data.currentPage,
        pageSize: res.data.pageSize,
        total: res.data.total,
        totalPages: res.data.totalPages,
      });
    } else {
      if (res.redirect) {
        history.push('/admin');
      }
      message.error(res.message);
    }
    setIsLoading(false);
  };

  const getUserList = async () => {
    setIsLoading(true);
    const postData = {
      type: 'Normal',
    };
    const res = await getUsersByType(postData);
    console.log({ res });
    if (res.success) {
      setUserList(
        res.data.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }))
      );
    } else {
      message.error(res.message);
    }
    setIsLoading(false);
  };

  const exportData = async () => {
    if (selectedCodes.length > 0) {
      const data = selectedCodes.map((el, i) => ({
        No: i + 1,
        Id: el.id,
        Code: el.code,
        Link: `${BASEURL}${el.code}`,
        Pin: el.securePin ? el.securePin : '-',
        IsUsed: el.isUsed ? 'Yes' : 'No',
        User: el.user ? `${el.user.firstName} ${el.user.lastName}` : 'Guest',
        PlayCount: el.playCount ? el.playCount : '-',
        CreatedDate: moment(el.createdAt).format('DD/MM/YYYY HH:mm'),
        UpdatedDate: moment(el.updatedAt).format('DD/MM/YYYY HH:mm'),
      }));
      console.log({ data });
      const csvData = await jsonToCsv(data);
      downloadCsv(csvData, 'codes.csv');
    } else {
      // toast.error('No data available for export');
    }
  };

  const removeFileLinkWithCode = async (
    id,
    s3Fullpath,
    code,
    method = 'PUT'
  ) => {
    setIsLoading(true);
    let putData = {};
    if (s3Fullpath !== null && s3Fullpath !== undefined && s3Fullpath !== '') {
      const s3Key = s3Fullpath.replace(App.BUCKET_URL, '');
      const s3 = new S3();
      const res = await s3.deleteS3Object(s3Key);
      console.log(res);
    }

    if (method === 'DELETE') {
      putData = {
        codeIds: [ id ],
      };
    } else {
      putData = {
        code,
        filePath: null,
        playCount: 0,
        isUsed: 0,
        fileType: null,
      };
    }
    await submitCode({ putData, method });
    setIsLoading(false);
    // await createCode({ id, filePath: '' });
    await getAllCodesApi();
  };

  return (
    <Layout className="main-layout">
      <Content style={{ padding: '0 1rem' }}>
        <div className="card-container">
          <Card className="bootstrap-container" bordered={false}>
            <Header />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h1>Codes</h1>
              {pwa_storage &&
              pwa_storage.user &&
              pwa_storage.user.type === 'Admin' && (
                <Link to="/admin/subscriptions">Go to subscription Page</Link>
              )}
              <Link to="/admin/requests">Go to Request Page</Link>
            </div>

            <div
              style={{
                marginBottom: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Button
                  onClick={exportData}
                  disabled={selectedCodes.length === 0}
                  style={{ marginRight: '15px' }}
                >
                  Export Selected Codes
                </Button>
                {pwa_storage &&
                pwa_storage.user &&
                pwa_storage.user.type === 'Admin' && (
                  <Select
                    onChange={(value) => {
                      let data = { ...tableSettings };
                      data.currentPage = 1;
                      if (value) {
                        data.filter = { user_id: value };
                      } else {
                        data.filter = {};
                      }
                      setTableSettings({ ...data });
                      getAllCodesApi({ ...data });
                      console.log({ value });
                    }}
                    allowClear={true}
                    placeholder="Select User"
                    options={[
                      { label: 'Guest', value: 'Guest' },
                      ...userList,
                    ]}
                    style={{ minWidth: '200px' }}
                  />
                )}
                <Tooltip title="Reload">
                  <Button
                    type="text"
                    shape="circle"
                    icon={<ReloadOutlined />}
                    onClick={getAllCodesApi}
                  />
                </Tooltip>
              </div>
              {pwa_storage &&
              pwa_storage.user &&
              pwa_storage.user.type === 'Admin' && (
                <div>
                  <Button onClick={() => setIsModalVisible(true)}>
                    Create Codes
                  </Button>
                </div>
              )}
            </div>
            <Table
              columns={columns}
              dataSource={codes}
              loading={isLoading}
              // size="middle"
              selections={true}
              bordered={true}
              rowSelection={{
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                  console.log({ selectedRowKeys, selectedRows });
                  setSelectedCodes(selectedRows);
                  setSelectedRowKeys(selectedRowKeys);
                },
              }}
              scroll={{ y: 240 }}
              pagination={{
                showQuickJumper: true,
                showSizeChanger: true,
                // defaultCurrent={2},
                total: tableSettings.total,
                showTotal: (total) => `Total ${total} items`,
                onChange: (page, pageSize) => {
                  const newTableSettings = {
                    ...tableSettings,
                    currentPage: page,
                    pageSize,
                  };
                  setTableSettings({ ...newTableSettings });
                  getAllCodesApi(newTableSettings);
                  // reset the selected code as we chnage the pagination
                  setSelectedCodes([]);
                  setSelectedRowKeys([]);
                },
              }}
              onChange={(pagination, filters, sorter) => {
                const sortArray = [];
                console.log({ pagination });
                if (sorter) {
                  if (sorter.column) {
                    sortArray.push([
                      sorter.columnKey,
                      sorter.order === 'ascend' ? 'asc' : 'desc',
                    ]);
                  }
                  const newTableSettings = {
                    ...tableSettings,
                    sortBy: sortArray,
                    currentPage: 1,
                  };
                  setTableSettings({ ...newTableSettings });
                  getAllCodesApi(newTableSettings);
                }
              }}
              showSorterTooltip={true}
            />
          </Card>
        </div>
        <GenerateCode
          isModalVisible={isModalVisible}
          handleCancel={() => setIsModalVisible(false)}
          userList={userList}
          getAllCodesApi={getAllCodesApi}
        />
        <SecuerPin
          isModalVisible={isPinModalVisible}
          handleCancel={handlePinModalClose}
          getAllCodesApi={getAllCodesApi}
          data={{
            code: activePinCode,
            securePin: activeSecurePin,
          }}
        />
      </Content>
    </Layout>
  );
};

export default Codes;
