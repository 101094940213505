import { Button, Card, Form, Input, Spin, message } from 'antd';
import React, { useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { submitCode } from '../../api';
// import { useParams } from 'react-router-dom';

const Urluplod = (props) => {
  const { backLink, codeDetails } = props;
  // const { mediaId } = useParams();

  const [ isLoading, setIsLoading ] = useState(false);

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      const putData = {
        code: codeDetails.code,
        filePath: values.url,
        fileType: 'Url',
        isUsed: true,
      };
      await submitCode({ putData, method: 'PUT' });
      setIsLoading(true);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      message.error(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  if (isLoading) {
    return (
      <Card className="custom-card" bordered={false}>
        <Spin tip="Processing..." />
      </Card>
    );
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <div className="welcome-section">
        <h1>Welcome to QuickCloudCodes</h1>
      </div>
      <Card className="custom-card" bordered={false}>
        <Button
          type="link"
          icon={<ArrowLeftOutlined />}
          style={{ position: 'absolute', left: 0 }}
          onClick={backLink}
        >
          Back
        </Button>
        <h1>Add Url</h1>
        <div>
          <Form
            // {...layout}
            layout="vertical"
            name="basic"
            // initialValues={{
            //   remember: true,
            // }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Media Link"
              name="url"
              rules={[
                {
                  required: true,
                  message: 'Please input your media link!',
                },
                { type: 'url', message: 'Please input valid media link' },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default Urluplod;
