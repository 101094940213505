import * as App from '../../helper/constants';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Progress, Spin, message } from 'antd';
import React, { useState } from 'react';

import S3 from 'aws-sdk/clients/s3';
import VideoRecorder1 from 'react-video-recorder';
// import moment from 'moment';
import { submitCode } from '../../api';
import { useParams } from 'react-router-dom';

const VideoRecorder = (props) => {
  const { backLink, codeDetails } = props;
  const { mediaId } = useParams();

  const [ displayUpload, setDisplayUpload ] = useState(false);
  const [ videoUrl, setVideoUrl ] = useState(null);
  const [ videoBlob, setVideoBlob ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ uploadProgress, setUploadProgress ] = useState(0);

  const submitVideo = async (file = null) => {
    try {
      setIsLoading(true);
      const s3 = new S3({
        region: 'us-east-2',
        accessKeyId: App.AWS_ACCESS_KEY,
        secretAccessKey: App.AWS_SECRET_ACCESS_KEY,
        apiVersion: '2006-03-01',
        params: { Bucket: App.APP_BUCKET },
      });
      const d = new Date();
      // const fullDateTime = moment(d).format('YYYY-MM-DD HH:mm:00');
      const fileName = mediaId && mediaId !== '' ? mediaId : d.getTime();

      let s3Key = `${fileName}.webm`;
      const file_config = {
        Body: videoBlob,
        Key: s3Key,
        ContentType: videoBlob.type,
      };

      s3
        .upload(file_config, async (err, data) => {
          if (err) {
            console.log('errrr', err);
            message(err.message);
            setDisplayUpload(false);
            setIsLoading(false);
          } else {
            console.log({ data });
            let filePath = `${App.BUCKET_URL}${s3Key}`;
            const putData = {
              code: codeDetails.code,
              filePath: filePath,
              fileType: 'Video',
              isUsed: true,
            };
            await submitCode({ putData, method: 'PUT' });
            // resetRecord();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            setVideoUrl(data.Location);
            setDisplayUpload(false);
            setIsLoading(false);
          }
        })
        .on('httpUploadProgress', function(progress) {
          let uploaded = parseInt(progress.loaded * 100 / progress.total);
          setUploadProgress(uploaded);
        });
    } catch (error) {
      message.error(error.message);
      // console.log(error);
    }
  };

  if (isLoading && uploadProgress > 0) {
    return (
      <Card className="custom-card" bordered={false}>
        <Spin tip="Processing..." />
        <Progress percent={uploadProgress} status="active" />
      </Card>
    );
  } else if (isLoading) {
    return (
      <Card className="custom-card" bordered={false}>
        <Spin tip="Processing..." />
      </Card>
    );
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <div className="welcome-section">
        <h1>Welcome to QuickCloudCodes</h1>
      </div>
      {!videoUrl && (
        <Card className="custom-card" bordered={false}>
          <Button
            type="link"
            icon={<ArrowLeftOutlined />}
            style={{ position: 'absolute', left: 0 }}
            onClick={backLink}
          >
            Back
          </Button>
          <h1>Record Video</h1>
          <VideoRecorder1
            onStartRecording={() => {
              setDisplayUpload(false);
            }}
            onRecordingComplete={(videoBlob1) => {
              // Do something with the video..
              setDisplayUpload(true);
              setVideoBlob(videoBlob1);
            }}
          />
          {displayUpload && (
            <Button
              style={{ marginTop: '15px' }}
              className="mt-5"
              type="primary"
              onClick={submitVideo}
            >
              Upload
            </Button>
          )}
        </Card>
      )}
      {videoUrl && (
        <video width="320" height="240" controls>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

export default VideoRecorder;
