import { API_BASE_URL } from '../helper/constants';
import axios from 'axios';

// axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.baseURL = API_BASE_URL;

/**
 * Set Global header 
 */
// export const setToken = (token = null) => {
// 	if (token) {
// 		axios.defaults.headers.common['Authorization'] = token;
// 	} else {
// 		const { signInUserSession } = JSON.parse(localStorage.getItem('pwa_storage'));
// 		if (signInUserSession) {
// 			const { idToken } = signInUserSession;
// 			axios.defaults.headers.common['Authorization'] = idToken.jwtToken;
// 		}
// 	}
// };
export const setToken = (token = null) => {
  if (token) {
    axios.defaults.headers.common['x-access-token'] = token;
  } else {
    const { token } = JSON.parse(localStorage.getItem('pwa_storage'));
    if (token) {
      axios.defaults.headers.common['x-access-token'] = token;
    }
  }
};

const handleError = async (error) => {
  console.log('handleError -> error', error);
  console.log('handleError -> error.response', error.response);

  if (error && error.response) {
    if (error.response.status === 401 || error.response.status === 403) {
      // toast.error("Unauthorized Please login again!");
      localStorage.setItem('pwa_storage', JSON.stringify({ isLogin: false }));
      return { status: false, message: error.message, redirect: true };
    } else if (error.response.status === 404 || error.response.status === 422) {
      return { status: false, message: error.message };
    } else if (error.response.status === 500) {
      return { status: false, message: error.message };
    } else {
      return { status: false, message: error.message };
    }
  } else {
    localStorage.setItem('pwa_storage', JSON.stringify({ isLogin: false }));
    console.log('Unexpected Error');
    console.log(error);
    return { status: false, message: error.message };
  }
};

export const login = async (postData) => {
  try {
    const res = await axios.post('/auth/login', postData);
    return res.data;
  } catch (error) {
    return await handleError(error);
  }
};

export const signUp = async (postData) => {
  try {
    const res = await axios.post('/auth/signup', postData);
    return res.data;
  } catch (error) {
    return await handleError(error);
  }
};

export const getUsersByType = async (postData) => {
  try {
    const res = await axios.post(`/users`, postData);
    return res.data;
  } catch (error) {
    return await handleError(error);
  }
};

export const submitCode = async ({ putData, method }) => {
  method = method.toLowerCase() === 'put' ? 'PUT' : method.toLowerCase() === 'delete' ? 'DELETE' : 'POST';
  try {
    switch (method) {
      case 'POST':
        const postRes = await axios.post(`/code`, putData);
        return postRes.data;
      case 'PUT':
        const putRes = await axios.put(`/code`, putData);
        return putRes.data;
      case 'DELETE':
        console.log({ putData });
        const deleteRes = await axios.delete(`/code`, {
          data: putData
        });
        return deleteRes.data;
      default:
        break;
    }
  } catch (error) {
    return await handleError(error);
  }
};

export const createCode = async (postData = {}) => {
  try {
    const res = await axios.post(`/code`, postData);
    return res.data;
  } catch (error) {
    return await handleError(error);
  }
};

export const getCodeById = async (codeId) => {
  try {
    const res = await axios.get(`/code/${codeId}`);
    return res.data;
  } catch (error) {
    return await handleError(error);
  }
};

export const getCodesByPagination = async (postData) => {
  try {
    const res = await axios.post(`/code/pagination`, postData);
    return res.data;
  } catch (error) {
    return await handleError(error);
  }
};

export const getSubscriptionsByPagination = async (postData) => {
  try {
    const res = await axios.post(`/subscribe/pagination`, postData);
    return res.data;
  } catch (error) {
    return await handleError(error);
  }
};

export const deleteSubscription = async (deleteData) => {
  try {
    console.log({ data: deleteData });
    const res = await axios.delete(`/subscribe`, { data: deleteData });
    return res.data;
  } catch (error) {
    return await handleError(error);
  }
};

export const getRequestsByPagination = async (postData) => {
  try {
    const res = await axios.post(`/request/pagination`, postData);
    return res.data;
  } catch (error) {
    return await handleError(error);
  }
};

export const submitRequest = async ({ putData, method }) => {
  method = method.toLowerCase() === 'put' ? 'PUT' : method.toLowerCase() === 'delete' ? 'DELETE' : 'POST';

  try {
    switch (method) {
      case 'POST':
        const postRes = await axios.post(`/request`, putData);
        return postRes.data;
      case 'PUT':
        const putRes = await axios.put(`/request`, putData);
        return putRes.data;
      case 'DELETE':
        const deleteRes = await axios.delete(`/request`, { data: putData });
        return deleteRes.data;
      default:
        break;
    }
  } catch (error) {
    return await handleError(error);
  }
};

export const getAllGroupCodes = async () => {
  try {
    const res = await axios.get(`https://8q5howyln1.execute-api.us-east-2.amazonaws.com/dev/pwa-code-group`);
    return res.data;
  } catch (error) {
    return await handleError(error);
  }
};

export const submitGroupCodes = async (postData) => {
  try {
    const res = await axios.post(`https://8q5howyln1.execute-api.us-east-2.amazonaws.com/dev/pwa-code-group`, postData);
    return res.data;
  } catch (error) {
    return await handleError(error);
  }
};

export const getAllStates = async () => {
  try {
    const res = await axios.get(`/api/states`);
    return res.data;
  } catch (error) {
    return await handleError(error);
  }
};

export const submitClient = async ({ putData, method }) => {
  method = method.toLowerCase() === 'put' ? 'PUT' : method.toLowerCase() === 'delete' ? 'DELETE' : 'POST';

  try {
    switch (method) {
      case 'POST':
        const postRes = await axios.post(`/api/clients`, putData);
        return postRes.data;
      case 'PUT':
        const putRes = await axios.put(`/api/clients`, putData);
        return putRes.data;
      case 'DELETE':
        const deleteRes = await axios.delete(`/api/clients`, { data: putData });
        return deleteRes.data;
      default:
        break;
    }
  } catch (error) {
    return await handleError(error);
  }
};

export const ConvertM4aTomp3 = async (key) => {
  try {
    const res = await axios.get(`https://api.quickcloudcodes.com/conv2mp3/${key}`);
    return res.data;
  } catch (error) {
    return await handleError(error);
  }
};
